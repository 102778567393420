@import "../../assets//scss/partials/vars";

.section-testimonies {
  display: block;
  position: relative;
  top: 1px;
  left: 0px;
  width: 100%;
  // height: 60vh;
  min-height: 650px;
  margin: 70px 0 100px;
  @media screen and (max-width: 767px) {
    // height: auto;
    // min-height: unset;
    min-height: 70vh;
    height: 100%;
  }
  @media screen and (max-height: 850px) {
    min-height: 550px !important;
  }
  user-select: none;
  -webkit-user-select: none;
}

#TestimoniesScrollpoint {
  display: block;
  position: relative;
  height: 1px;
}

.loading-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: none;
}

.sliver-container {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media (hover) {
    &:hover .img-block {
      filter: grayscale(1);
    }
  }

  @media (hover: none) {
    &:active .img-block {
      filter: grayscale(1);
    }
  }
}

.testimony-display-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // background-color: rgba(200, 0, 0, 0.4);
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  // background-color: purple;
  // opacity: 0.5;
  user-select: none;
  -webkit-user-select: none;
}

.covertitle {
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  bottom: -20px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.testimony-container-left {
  flex-grow: 3;
  flex-basis: auto;
  // background-color: chartreuse;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .image-2 {
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    top: 0%;
    left: 50%;
    width: 80%;
    height: 100%;
    max-width: 600px;
    max-height: 400px;
    transform: translateX(-50%);
    z-index: 1;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  .image-1 {
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    top: 30%;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
    z-index: 2;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  .profileImage {
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 250px;
    z-index: 2;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  @media screen and (max-width: 767px) {
    min-height: 40vw;
    width: 100%;
    // background-color: green;

    .image-2 {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      max-width: unset;
      transform: translateX(0%);
    }

    .image-1 {
      top: 10%;
      left: 10%;
      max-width: 30vw;
      max-height: 20vw;
    }

    .profileImage {
      top: 20%;
      max-width: 20vw;
      max-height: 30vw;
    }
  }
}

.testimony-container-right {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 3;
  flex-basis: auto;
  overflow: hidden;
  // background-color: chocolate;
  padding: 20px 20px 0px;
  z-index: 5;
  width: 100%;
  box-sizing: border-box;

  .upper-container {
    flex-shrink: 1;
    flex-grow: 0;
    padding-bottom: 20px;
  }

  .lower-container {
    flex-grow: 2;
    flex-grow: 1;
    max-width: fit-content;
    padding-right: 10px;
    overflow-y: auto;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      background: linear-gradient(rgba(12, 12, 12, 0.001), rgba(12, 12, 12, 1));
      pointer-events: none;
    }
  }

  .testimony-name {
    text-transform: uppercase;
    font-size: 1.3em;
  }

  .country-time {
    font-size: 0.6em;
  }

  .country-flag {
    padding-top: 10px;
    padding-right: 5px;
    height: 100%;
    max-height: 10px;
  }
}

.testimony-container-left {
  flex-grow: 4;
  position: relative;

  .title-block {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);

    .img-block {
      width: 300px;
      height: 300px;
    }
  }
}

.testimony-container-right {
}

.sliver {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid transparent;
  box-shadow: 10px 0px 60px 10px rgba(12, 12, 12, 0.5);
  @media screen and (max-width: 767px) {
    box-shadow: 0px 0px 30px 0px rgba(12, 12, 12, 0.5);
    // -3px 5px 5px -3px #000;
  }

  .image-hide-overflow {
    display: flex;
    flex-grow: 12;
    overflow: hidden;
    align-items: stretch;
    flex-shrink: 0;
  }

  .img-block {
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    // height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s, transform 0.5s;
    filter: grayscale(0);
    transform-origin: center;
    transform: scale(1);
    min-height: 100px;
    // background-color: red;

    .solid-fill {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // .solid-block {
  //   display: block;
  //   position: relative;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(255,0,0,0.);
  // }

  .sliver-block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
  }

  .sliver-title {
    flex-grow: 1;
  }

  @media (hover) {
    &:hover {
      .img-block {
        filter: grayscale(0);
        transform: scale(1.05);
      }

      .sliver-title {
        opacity: 1;
      }
    }
  }

  @media (hover: none) {
    &:active {
      .img-block {
        filter: grayscale(0);
        transform: scale(1.05);
      }

      .sliver-title {
        opacity: 1;
      }
    }
  }
}

.curtain {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $emergence-black;
}

.sliver-title-container {
  display: flex;
  flex-grow: 3;
  flex-shrink: 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.sliver-title-mobile {
  display: none;
  @media screen and (max-width: 767px) {
    box-sizing: content-box;
    text-transform: uppercase;
    font-family: "Roboto";
    letter-spacing: 8px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // padding: 0.2em;

    img {
      display: inline-block;
      height: 100%;
      max-height: 11px;
      padding-left: 8px;
      fill: white;
    }
  }
}

.sliver-title {
  display: block;
  position: relative;
  padding: 0.2em;
  height: 0;
  width: 0;
  word-wrap: break-word;
  box-sizing: content-box;
  font-family: $headerFont;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.2em;
  line-height: 1em;
  color: $emergence-white;

  transition: opacity 0.5s;
  opacity: 0;
}

.exit-button {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  height: 25px;
  padding: 20px;
  cursor: pointer;
  font-size: 2em;

  & .close-img {
    width: 100%;
    height: 100%;
    max-width: 25px;
    max-height: 25px;
  }
}

.pos-block {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.glitch-effect > div {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

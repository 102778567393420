@import "../../assets/scss/styles.scss";
@import "../../../node_modules/react-modal-video/scss/modal-video.scss";

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.content {
  h1 {
    margin-top: 100px;
    // temp margin
    margin-top:120px;
    text-transform: uppercase;
    font-size: 7vw;
    line-height: 0.9;
    @media (min-width: $screen-md) {
      font-size: 5vw;
      // TEMP MARGIN while video is hidden
      margin-top:190px;
    }
    @media (min-width: $screen-xl) {
      font-size: 7rem;
    }
  }
  .sub-caption {
    font-weight: 600;
    font-size: 3.5vw;
    margin-top: 20px;
    @media (min-width: $screen-md) {
      font-size: 2.5vw;
    }
    @media (min-width: $screen-xl) {
      font-size: 3rem;
    }
    span {
      font-size:2.6rem;
      font-weight:500;
    }
  }
}

.modal-video-movie-wrap {
  width:80%;
  margin:0 auto;
  background-color:$emergence-black;
  @media(min-width:$screen-lg) {
    width:95%;
  }
}

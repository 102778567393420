@import "../../assets/scss/styles.scss";

section.actions {
  //margin-top:150px;
  font-size:3rem;

  h2 {
    margin-top:60px;
    text-transform:uppercase;
    line-height:.9;
    margin-bottom:70px;
    &:lang(fr) {
      line-height:1.1;
    }
  }

  .steps-container {

    .step-block {
      display:block;
      min-height:350px;
      position:relative;
      color:$emergence-black;
      vertical-align:top;
      margin-bottom:4%;
      @media(min-width:$screen-xs) {
        display:inline-block;
       // width:50%;
        min-height:400px;
      }
      @media(min-width:$screen-sm) {
        display:inline-block;
        width:100%;
        min-height:330px;
        &.step-one, &.step-three {
          margin-right:1%;
        }

        &.step-two, &.step-four {
          margin-left:1%;
        }

        &.step-one, &.step-2 {
        //  margin-bottom:2%;
        }
      }
      @media(min-width:$screen-md) {
        display:inline-block;
        min-height:300px;
      //  width:23.5%;
        width:100%;
      //  margin:0 1%;
        &:first-child {
          margin-left:0;
        }
        &:last-child {
          margin-right:0;
        }
      }
    }

    .step-block-animation {

      width:100%;
      display:inline-block;
      margin-bottom:20px;
      vertical-align:top;

      @media(min-width:$screen-sm) {
        display:inline-block;
        width:48%;
        min-height:330px;

        &:nth-of-type(odd) {
          margin-right:1%;
          margin-bottom:1%;
        }

        &:nth-of-type(even) {
          margin-left:1%;
          margin-bottom:1%;
        }
      }

      @media(min-width:$screen-md) {
        width:23.5%;
        min-height:330px;

    

      @media(min-width:$screen-xl) {
        &:nth-of-type(odd) {
          margin-right:1%;
        }

        &:nth-of-type(even) {
          margin-left:1%;
        }
      }
        margin:0 1%;
        &:first-child {
          margin-left:0;
        }
        &:last-child {
          margin-right:0;
        }
      }
    
    }

    p {
      color:$emergence-black;
      font-size:1.6rem;
      max-width:85%;
      padding:10px;
      padding-top:20px;
      transition: all .2s ease-in-out;
      @media(min-width:$screen-sm) {
        padding-top:20px;
        font-size:1.3rem;
      }
      @media(min-width:$screen-md) {
        font-size:1.3rem;
      }
    }
    .step-one {
      background-color:#ddb464;
      background-image: url("../SectionActions/images/bg-1.png");
      background-size:cover;
      transition: all .2s ease-in-out;
     
      &:hover {
        p {
          color:#fff;
        }
        transform:scale(1.05);
        background-image: url("../../components/Testimonies/images/mark-close.jpg");
        background-size:cover;
      }
    }
    .step-two {
      background-color:#8b8860;
      background-image: url("../SectionActions/images/bg-2.png");
      background-size:cover;
      transition: all .2s ease-in-out;
      &:hover {
        p {
          color:#fff;
        }
        transform:scale(1.05);
        background-image: url("../../components/Testimonies/images/pich-close.jpg");
        background-size:cover;
      }
    }
    .step-three {
      background-color:#595154;
      background-image: url("../SectionActions/images/bg-3.png");
      background-size:cover;
      transition: all .2s ease-in-out;
      &:hover {
        p {
          color:#fff;
        }
        transform:scale(1.05);
        background-image: url("../../components/Testimonies/images/lynn-close.jpg");
        background-size:cover;
       
      }
    }
    .step-four {
      background-color:#673c4a;
      background-image: url("../SectionActions/images/bg-4.png");
      background-size:cover;
      transition: all .2s ease-in-out;
      &:hover {
        p {
          color:#fff;
        }
        transform:scale(1.05);
        background-image: url("../../components/Testimonies/images/melania-close.jpg");
        background-size:cover;
      }
    }
    span {
      font-family:$stepNumberFont;
      font-style: normal;
      font-weight: 400;
      padding-left:5px;
      color:$emergence-black;
      font-size:50vw;
      @media(min-width:$screen-sm) {
        font-size:30vw;
      }
      @media(min-width:$screen-md) {
        font-size:15vw;
      }
      @media(min-width:$screen-xl) {
        font-size:18rem;
      }
      position: absolute;
      bottom: 0;
      line-height:.75;
    }
  }
}
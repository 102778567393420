@import "_vars.scss";

:root {
  font-size: 62.5%;
}

html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}

// Make 100% height of browser window
body,
#root {
  height: 100%;
  font-size: 1.6rem;
  /*  position: relative; */
}

// General wrapper for site
.wrapper {
  display: block;
  min-height: 100%;
  //overflow: hidden;
}
// General blockwrap, wrapper for full width containers, always 100% width
.blockwrap {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
// General content container
.container {
  display: block;
  position: relative;
  // $screen-md = px;
  width: 100%;
  max-width: $screen-lg; // 1024px
  // margin: 0 auto;
  // text-align: center;
}

.content {
  display: block;
  //margin: 0 auto;
  padding: 0 15px 0 15px;
  //text-align: center;

  @media (min-width: $screen-md) {
    padding: 0 20px 0 20px;
  }

  img {
    max-width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5 {
  // font-family: $baseFont, Arial, Helvetica, sans-serif;
  font-weight: 300;
}

a {
  transition: 0.4s ease all;
  outline: none;
  border: none;
}
a:hover {
  color: red;
}
a:visited {
}
/* :link { color: #0000EE; } */
/* :visited { color: $raft-green; } */

/* HELPE
/* HELPERS */

.show {
  display: block;
}
.hide {
  display: none !important;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.side-half {
  display: inline-block;
  width: 49%;
}

.not-visible {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: opacity 3.25s ease-in-out;
}

br.mobile {
  display: none;
  @media (min-width: $screen-md) {
    display: block;
  }
}
html:lang(en) {
}
html:lang(fr) {
}

@import "../../assets/scss/styles.scss";

section.sponsors {
  .container {
    padding-top:0;
  }
  max-width:$screen-xl;
  padding: 0 20px 0 20px;
  margin:50px auto;

  .sponsor-list {
    font-size:1.1rem;
  }

  .collab, .official-presenter {
    display:inline-block;
    width: 50%;
    vertical-align:top;
    margin-bottom:20px;
    img {
      width:60%;
    }
  }

  .partner-sponsor {
    display:inline-block;
    width: 50%;
    vertical-align:top;
    margin-bottom:20px;
    img {
      width:60%;
    }
  }

  .sponsor-pt-2 {
    display:inline-block;
    width:50%;
    vertical-align:top;
    margin-bottom:20px;
    img {
      width:60%;
    }
  }

  .media-partner {
    display:inline-block;
    width:50%;
    vertical-align:top;
    margin-bottom:20px;
    img {
      width:40%;
    }
  }

  .help-resource {
    display:inline-block;
    width:100%;
    vertical-align:top;
    img {
      width:18%;
    }
  }

  @media(min-width:$screen-md) {
    .collab, .official-presenter, .partner-sponsor, .sponsor-pt-2, .media-partner {
      display:inline-block;
      width:13%;
      vertical-align:top;
    }
  
    .collab, .official-presenter {
      img {
        width:80%;
      }
    }
  
    .partner-sponsor {
      img {
        width:80%;
      }
    }
  
    .media-partner {
      img {
        width:40%;
      }
    }
  
    .help-resource {
      display:inline-block;
      vertical-align:top;
      width:35%;
      img {
        width:20%;
      }
    }
  }
}

footer {
  text-align:center;
  color:$emergence-accent;
  background-color:$emergence-white;
  padding:40px 0;
  a {
   // margin:0 5px;
  }
  p {
    font-size:1.3rem;
    a {
      color:$emergence-accent;
      text-decoration:underline;
    }
  }
  p {
    font-size:1.3rem;
    a {
      color:$emergence-accent;
      text-decoration:underline;
    }
  }
  svg {
    transform: scale(0.5);
    transform-origin: top;
    border-radius: 40px;
    border: 5px solid black;
  }
}
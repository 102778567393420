@import '../../assets/scss/partials/vars';



.form-container form {
  color:$emergence-black;
  font-size:1.6rem;
  text-transform:uppercase;

  width: auto;

  @media(min-width:$screen-md) {
    max-width:460px;
    padding:0;
  }
  
  input {
    display:inline-block;
    width:100%;
    //max-width:400px;
    border:none;
   // padding:5px 10px;
    border-radius:0;
    border-bottom:1px solid $emergence-black;
    background-color:transparent;
    height:40px;
    @media(min-width:$screen-md) {

    }
  }

  #name {
    margin-top:20px;
  }
  
  label {
    display:inline-block;
    width:30%;
    vertical-align:bottom;
    //border-bottom:1px solid $emergence-black;
   
  }

  label {
    vertical-align:text-top;
    display:none;
  }
  
  textarea {
    display:inline-block;
    font-family:'Roboto';
    font-size:1.4rem;
    color:$emergence-black;
    width: 97.5%;
    padding: 10px 1%;
    height:150px;
    //padding:15px 0px;
    margin-top:40px;
    border-radius:0;
    border:1px solid $emergence-black;
    background-color:transparent;

  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $emergence-black;
    opacity: 1; /* Firefox */
    text-transform:uppercase;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $emergence-black
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:$emergence-black;
  }

  button {
    width:100.5%;
   // max-width:424px;
    height:50px;
    color:$emergence-white;
    background-color:$emergence-black;
    border:1px solid $emergence-black;
    font-size:1.6rem;
    text-transform:uppercase;
    margin-top:30px;
    margin-bottom:60px;
    transition:.5s ease all;
    &:hover {
      background-color:#cd7748;
      color:#fff;
      border:1px solid #cd7748;

    }
  }

  .error {
    color:red;
  }
}

.submitted {
  color:$emergence-bright;
  font-size:2rem;
  margin:50px 0 100px 0;
}
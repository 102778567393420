@import "../../assets//scss/partials/vars";

.video-wrapper {
  position: relative;
  overflow: hidden;
  height: 500px;
  margin-top:75px;
  @media (min-width: $screen-md) {
    margin-top:120px;
    height: 80vh;
  }

  .video-button {
    display: block;
    position: relative;
    border: 0;
    background-color: transparent;
    z-index: 9;
    top: 40%;
    height: 100px;
    width: 100px;

    @media(min-width:$screen-md) {
      top:40%;
      height: 140px;
      width: 140px;
    }
    cursor: pointer;
    margin: 0 auto;
   
    background-image: url("../../assets/images/play-btn-hover.png");
    background-size: contain;
    background-repeat: no-repeat;

  
    &:hover {
    
    }
  }

  .video-button:before {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: url("../../assets/images/play-btn-hover.png");
    background-size:contain;
    opacity:.5;
    background-position:5px 0;
    background-repeat:no-repeat;
    display:none;
  
  }

  .video-button:hover:before {
    display:block;
    animation: btnAnimate .75s step-end infinite;
    z-index:-1;
  }

  @keyframes btnAnimate {
    0% {
      background-position:0 0;
      filter:hue-rotate(0deg);

    }
    10% {
      background-position:3px 0;
    }
    20% {
      background-position:-9px -6px;
   
    }
    30% {
      background-position:-3px 5px;
      background-position-y:70px;
      background-size: 140px 20px;
      filter: invert(66%) sepia(57%) saturate(3200%) hue-rotate(73deg) brightness(106%) contrast(120%);
    }

    35%{
      background-position:30px 30px;
      background-size:140px 10px;
      filter: invert(9%) sepia(93%) saturate(7496%) hue-rotate(1deg) brightness(112%) contrast(94%);
    }
    40% {
     
      background-size:140px 100px;
      filter: invert(9%) sepia(93%) saturate(7496%) hue-rotate(1deg) brightness(112%) contrast(94%);
  
      
    }
    41% {
      background-position:-3px 5px;
     // background-position:5px 2px;
 
     //filter: invert(9%) sepia(93%) saturate(7496%) hue-rotate(1deg) brightness(112%) contrast(94%);
    
    }
    50% {
      background-position:5px -6px;
      filter:none;
      background-size: 140px 140px;

    }
    70% {
      background-position:-3px 3px;
     // filter: invert(9%) sepia(93%) saturate(7496%) hue-rotate(1deg) brightness(112%) contrast(94%);
     
    }
    90% {
      background-position:-5px 1px;
      filter:none;
    }
    100% {
      background-position:0 0;
      filter:hue-rotate(360deg);
    }
  }
  


}
.video-bg,
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.video-overlay {
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none; /* Allows right clicking on the video to pause etc */
}
.video-bg video {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.callout-content {
  position: relative;
  text-align: center;
  margin: 50px 0; /* This adds some space around the video */
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

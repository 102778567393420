@import '../../scss/partials/vars';

.social-wrapper .instagram-icon, footer .instagram-icon {
  stroke:$emergence-black; 
  stroke-width:0; 
  transition: all 0.5s ease;
  border-radius:40px;

  &:hover {
    fill: #866E31;
    background-color:#fff;
    border:5px solid #866E31;
    //stroke: #fff;
 //   stroke-width: .5;
  }
}
@import '../../assets/scss/partials/vars';

.form-testimonials {

  @media(min-width:$screen-md) {
    background: linear-gradient(90deg, $emergence-white 50%, $emergence-bright 50%);
  }
  .container {
    padding-top:0;
    .content {
      padding:0;
      overflow:hidden;
      @media(min-width:$screen-md) {
        padding:0 20px;
      }
    }
  }
  font-size:3rem;
  background-color:$emergence-white;
  h2 {
    margin-top:60px;
    color:$emergence-black;
    line-height:0.9;
    &:lang(fr) {
      line-height:1.1;
    }
    text-transform:uppercase;
  }
  margin-top:100px;
  .form-container, .testimonials {
   // display:inline-block;
    width:100%;
    vertical-align:top;
    @media(min-width:$screen-md) {
      width:50%;
      display:inline-block;
    }
  }

  .form-container {
    //border:1px solid red;
    background-color:$emergence-white;
    padding:0 20px;
  width:auto;
    //margin:100px;
    @media(min-width:$screen-md) {
      padding:0;
      width:47%;
      margin-right:3%;
    }
  }

  .testimonials {

    background-color:$emergence-bright;
    display:inline-block;
    @media(min-width:$screen-md){
      width:47%;
      margin-left:3%;
    }
    p {
      font-size:1.6rem;
      margin: 70px 50px 40px 50px;
      @media(min-width:$screen-lg) {
        margin: 70px 70px 40px 70px;

      }
    }
  }

  .slick-prev:before, .slick-next:before {
    display:none;
  }

  .slick-slider {
    margin:3% auto 10% auto;
    width:90%;

    @media(min-width:$screen-md) {
      margin:10% auto 10% auto;
      width:100%;
    
    }
    h3 {
      display:inline;
      margin:50px;
      padding-top:10px;
      text-transform:uppercase;
      font-size:1.8rem;
      border-top:8px solid #fff;
      @media(min-width:$screen-md) {
        margin:70px;
      }
    }
    .slick-prev {
      left:0px;
      z-index:9;
      top:15%;
    }
    .slick-next {
      right:20px;
      z-index:9;
      top:75%;
    }

    .slick-dots li.slick-active button:before {
      color:#fff;
    }
  }
}
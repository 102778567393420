@import "./partials/base";
@import "./partials/vars";
@import "./partials//glitch";

body {
  background-color: $emergence-black;
  font-family: $baseFontRegular;
  color: $emergence-white;
  scroll-behavior: smooth;
}

.root-scrollbar {
  & .ps__rail-y {
    z-index: 12;
  }
}

.wrapper {
  //max-width:1200px;
  margin: 0 auto;
}

.container {
  max-width: $screen-xl;
  //padding-top:75px;
  margin: 0 auto;
}

h1 {
  font-family: $headerFont;
  font-style: normal;
  font-weight: 400;
  color: $emergence-white;
}

h2 {
  font-family: $subheaderFont;
  font-style: normal;
  font-weight: 400;
  color: $emergence-white;
}

a {
  color: $emergence-white;
  text-decoration: none;
  &:hover {
    color: $emergence-accent;
  }
}

@import "_vars.scss";


.glitch {
  position: relative;
  margin: 0 auto;
  color: $primaryColor;
  transform: scale3d(1,3,1);



  &::before,
  &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      background: $secondaryColor;
      color: $primaryColor;
      clip: rect(0, 900px, 0, 0);

  }

  &::before {
  //  left: 7px;
      text-shadow: 1px 0 green;
      animation: glitch-effect 1s infinite linear alternate-reverse;
      animation-duration:2.5s;

  }

  &::after {
   //   left: 3px;
      text-shadow: -2px 0 red;
      animation: glitch-effect 2s infinite linear alternate-reverse;
      animation-duration:2.5s;
  }
}

.glitch2 {
  position: relative;
  margin: 0 auto;
  color: $primaryColor;
  text-transform: uppercase;
  transform: scale3d(1,1,1);


  &::before,
  &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      background: $secondaryColor;
      color: $primaryColor;
      clip: rect(0, 900px, 0, 0);

  }

  &::before {
   //   left: 7px;
      text-shadow: 2px 0 green;
      animation: glitch-effect 1.5s infinite linear alternate-reverse;
      animation-duration:4s;
  }

  &::after {
   //   left: 3px;
      text-shadow: -1px 0 red;
      animation: glitch-effect .5s infinite linear alternate-reverse;
      animation-duration:4s;
  }
}

.glitch3 {
  position: relative;
  margin: 0 auto;
  color: $primaryColor;
  text-transform: uppercase;
  transform: scale3d(1,1,1);


  &::before,
  &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      background: $secondaryColor;
      color: $primaryColor;
      clip: rect(0, 900px, 0, 0);

  }

  &::before {
   //   left: 7px;
      text-shadow: 2px 0 green;
      animation: glitch-effect 2s infinite linear alternate-reverse;
      animation-duration:3s;

  }

  &::after {
   //   left: 3px;
      text-shadow: -1px 0 red;
      animation: glitch-effect 1s infinite linear alternate-reverse;
      animation-duration:3s;

  }
}

@keyframes glitch-effect{

 
  // @for $i from 0 through 10{
  //     #{percentage($i*(1/$steps))}{
  //         clip:rect(random(100)+px,9999px,random(100)+px,0);
  //     }
  // }
  0% {
   
    
 }
 4% {
  clip: rect(21px, 9999px, 39px, 0);
 }
  5% {
    clip: rect(9px, 9999px, 91px, 0);
 }
  10% {
    clip: rect(73px, 9999px, 18px, 0);
 }
  15% {
    clip: rect(0, 900px, 0, 0);
 }

 100% {
  clip: rect(0, 900px, 0, 0);

 }


}
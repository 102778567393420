@import '../../scss/partials/vars';

.social-wrapper .facebook-icon, footer .facebook-icon {
  //fill:#fff;
  stroke:$emergence-black; 
  stroke-width:0; 
  transition: all 0.5s ease;
  border-radius:40px;

  &:hover {
    //fill: $emergence-accent;
    fill: #BB694B;
    background-color:#fff;
    border:5px solid #BB694B;

    //stroke: #fff;
 //   stroke-width: .5;
  }
}
/* VARS - AGENCY STARTER WEBSITE ===============================
======================================================= */
$white: #FFFFFF;
$black: #000000;

$emergence-black:rgba(12,12,12,1); // #0c0c0c
$emergence-white: rgba(251,249,244,1); // #fbf9f4
$emergence-accent: rgba(127,127,127,1); // #7f7f7f
$emergence-bright: rgba(205, 119, 72, 1); // #cd7748;

 
// FONTS ===============================
$baseFontRegular: 'Roboto', sans-serif;
$baseFontBold: 'Roboto', sans-serif;

$headerFont: "Champion Middlewt A", "Champion Middlewt B", sans-serif;
$subheaderFont: "Champion Welterwt A", "Champion Welterwt B";
$stepNumberFont:  "Champion Heavywt A", "Champion Heavywt B";


// GLITCH ANIM ==================== */@at-root$primaryColor: #fff;
$primaryColor: $white;
$secondaryColor: $emergence-black;
$steps: 20;

// Container size
// smartphones (portrait view)
$screen-xs: 376px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;


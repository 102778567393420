@import "../../assets/scss/styles.scss";


.nav-wrapper {
  position:fixed;
  top:0;
  width:100%;
  background-color:$emergence-black;
  margin:0 auto;
  padding: 15px 0 20px 0;
  height: 40px;
  font-size: .7rem;
  z-index:10;
  &:lang(fr) {
    font-size:.7rem;
  }

  @media(min-width:$screen-xs) {
    font-size:1.1rem;
    &:lang(fr) {
      font-size:.9rem;
    }
  }
  @media(min-width:$screen-sm) {
    font-size: 1.2rem;
 
    &:lang(fr) {
      font-size:1.2rem;
    }
  }
  .date, .social-wrapper {
    display:none;
  }
  @media(min-width:$screen-md) {
    padding: 40px 0 20px 0;
    height: 60px;
    .date, .social-wrapper {
      display:block;
    }
  }
  nav, nav span {
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-wrap:wrap;/* so they do not overlap each other if space too short */
    align-items:flex-start;
  }

  nav {
    max-width:$screen-xl;
    margin:0 auto;
   
    text-transform:uppercase;
    a.logo-link {
      text-align:center;
      img {
        max-width:115px;
        @media(min-width:$screen-md) {
          max-width:140px;
        }
      }
    }
  
    span {
      flex:2;
    }
  
    span:first-of-type:after,
    span:last-of-type:before{
      content:'';
      display:inline-block;
    }

    span:first-child, span:last-child {
      padding: 0 15px;
      @media (min-width: $screen-md) {
        padding: 0 20px;
      }
    }

    span:last-child {
      font-size:1.2rem;
    }
  
    .social-wrapper a {
      display:inline-block;
      //margin:0 5px;
      svg {
        transform:scale(.5);
        transform-origin:top;
        border:5px solid #fff;
      }
    }
  
    p {
      line-height:1;
    }
  }
}

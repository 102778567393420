@import '../../scss/partials/vars';

.social-wrapper .twitter-icon, footer .twitter-icon {
  stroke:$emergence-black; 
  stroke-width:0; 
  transition: all 0.5s ease;
  border-radius:40px;

  &:hover {
   // fill: $emergence-accent;
   fill: #C39D72;
   background-color:#fff;
   border:5px solid #c39d72;
    //stroke: #fff;
 //   stroke-width: .5;
  }
}